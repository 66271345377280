import React from 'react';
import styled from 'styled-components';
import { Section } from 'react-scroll-section';
import { Heading } from 'rebass/styled-components';
import PropTypes from 'prop-types';

const SectionContainer = styled.div`
  min-height: 100vh;
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: 5em 2em;
  scroll-behavior: smooth;
  content-visibility: auto;

  @media (max-width: 400px) {
    padding: 4em 1em;
  }
`;

const DefaultBackground = () => <div />;

const Container = ({ id, children, Background = DefaultBackground }) => (
  <Section id={id} style={{ position: 'relative' }}>
    <Background />
    <SectionContainer>{children}</SectionContainer>
  </Section>
);

Container.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Background: PropTypes.func,
};

const Header = ({ name, icon = '', label = '' }) => (
  <Heading mb={4} className="sectionTitle">
    {name}
    {icon && (
      <span role="img" aria-label={label} style={{ marginLeft: '10px' }}>
        {icon}
      </span>
    )}
  </Heading>
);

const Centered = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;

Header.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
};

export default {
  Centered,
  Container,
  Header,
};
